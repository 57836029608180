



















import { defineComponent, reactive, watch } from "@vue/composition-api";
import { AxiosInstance } from "axios";
import { saveAs } from "file-saver";

export default defineComponent({
  props: {
    abstracts: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  setup(props, { root }) {
    const state = reactive({
      abstractsIds: [] as any,
      type: "",
      buttonDisabled: true,
    });

    const reportTypes = [
      {
        label: root.$tc("panel.event.abstract.list.reports.short"),
        value: "short",
      },
      {
        label: root.$tc("panel.event.abstract.list.reports.groupA"),
        value: "groupA",
      },
      {
        label: root.$tc("panel.event.abstract.list.reports.groupB"),
        value: "groupB",
      },
      {
        label: root.$tc("panel.event.abstract.list.reports.single"),
        value: "single",
      },
      {
        label: root.$tc("panel.event.abstract.list.reports.authors"),
        value: "authors",
      },
    ];

    watch(
      () => props.abstracts,
      () => {
        if (props.abstracts && props.abstracts.length) {
          state.abstractsIds = props.abstracts.map((el: any) => el.id);
          state.buttonDisabled = state.type ? false : true;
        } else {
          state.buttonDisabled = true;
        }
      }
    );

    watch(
      () => state.type,
      () => {
        if (props.abstracts && props.abstracts.length && state.type) {
          state.buttonDisabled = false;
        } else {
          state.buttonDisabled = true;
        }
      }
    );

    const getReport = () => {
      const axiosInstance = root.$store.getters[
        "api/getInstance"
      ] as AxiosInstance;

      if (state.type === "short") {
        axiosInstance
          .get(`event/${root.$route.params.id}/abstract-report/short`, {
            responseType: "blob",
            params: {
              abstractsIds: state.abstractsIds,
            },
          })
          .then((response) => {
            saveAs(response.data, "zestawienie-skrocone.xls");
          })
          .catch((error) => console.log(error));
      } else if (state.type === "groupA") {
        axiosInstance
          .get(`event/${root.$route.params.id}/abstract-report/group-a`, {
            responseType: "blob",
            params: {
              abstractsIds: state.abstractsIds,
            },
          })
          .then((response) => {
            saveAs(response.data, "zestawienie-gupowe-A.docx");
          })
          .catch((error) => console.log(error));
      } else if (state.type === "groupB") {
        axiosInstance
          .get(`event/${root.$route.params.id}/abstract-report/group-b`, {
            responseType: "blob",
            params: {
              abstractsIds: state.abstractsIds,
            },
          })
          .then((response) => {
            saveAs(response.data, "zestawienie-gupowe-B.docx");
          })
          .catch((error) => console.log(error));
      } else if (state.type === "single") {
        axiosInstance
          .get(`event/${root.$route.params.id}/abstract-report/compressed`, {
            responseType: "blob",
            params: {
              abstractsIds: state.abstractsIds,
            },
          })
          .then((response) => {
            saveAs(response.data, "zestawienie-pelne.zip");
          })
          .catch((error) => console.log(error));
      } else if (state.type === "authors") {
        axiosInstance
          .get(`event/${root.$route.params.id}/abstract-report/authors`, {
            responseType: "blob",
            params: {
              abstractsIds: state.abstractsIds,
            },
          })
          .then((response) => {
            saveAs(response.data, "zestawienie-autorow.xls");
          })
          .catch((error) => console.log(error));
      }
    };
    return { state, reportTypes, getReport };
  },
});
